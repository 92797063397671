<template>
  <div class="flex items-center gap-5 w-full">
    <nav
      v-if="props.section.data?.value.shownElements?.items"
      class="w-full flex gap-5 flex-col items-center md:flex-row"
      :class="[
        props.section.styles.textAlignment.value,
      ]"
    >
      <button
        v-for="(navItem, index) in props.section.data?.value.items"
        :key="index"
        v-animated-scroll-to="`#section-${navItem.targetSection}`"
        type="button"
        class="font-semibold mb-4 md:mb-0 text-2xl text-black md:text-(fg-default) md:text-base hover:cursor-pointer"
        @click="emit('item-clicked')"
      >
        {{ navItem[props.section.lang.value]?.text }}
      </button>
    </nav>
    <button
      v-if="props.section.data?.value.shownElements?.ctaButton"
      v-animated-scroll-to="`#section-${props.section.data?.value.ctaButton.targetSection}`"
      type="button"
      class="page-button whitespace-nowrap"
      @click="emit('item-clicked')"
    >
      <span v-html="sanitize(props.section.data?.value.ctaButton[props.section.lang.value]?.text)" />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  section: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['item-clicked']);
</script>
