<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <div
      class="container flex items-center justify-between gap-5 min-h-[3.75rem] relative z-40 md:static md:z-auto"
    >
      <div
        v-show="section.data?.value.shownElements?.logo"
        class="inline-block"
        :class="[
          section.styles.boxedLogo.value,
        ]"
      >
        <img
          :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-240,c-at_max/' : ''}${section.data?.value.logoSource}`"
          class="h-[2.125rem] w-auto object-contain"
          :alt="eventTitle"
        >
      </div>
      <PageBarNavigationItems
        :section="section"
        class="hidden md:flex"
      />
      <button
        type="button"
        class="md:hidden"
        @click="isBurgerMenuOpen = !isBurgerMenuOpen"
      >
        <Lottie
          ref="burgerLottieRef"
          :animation-data="burgerLottie"
          :height="20"
          :width="20"
          :speed="1.5"
          class="lottie-burger"
          :auto-play="false"
          :loop="false"
        />
      </button>
    </div>
    <transition
      name="custom-classes"
      :enter-from-class="isBurgerMenuOpen ? '-translate-y-4 opacity-0' : 'translate-y-4 opacity-0'"
      :leave-active-class="isBurgerMenuOpen ? 'translate-y-4 opacity-0' : '-translate-y-4 opacity-0'"
      :duration="300"
      mode="out-in"
    >
      <div
        v-show="isBurgerMenuOpen"
        class="transition-all duration-300 fixed top-0 left-0 h-[100dvh] w-screen z-30 bg-white md:hidden flex items-center justify-center"
      >
        <PageBarNavigationItems
          :section="section"
          class="container flex-col"
          @item-clicked="isBurgerMenuOpen = false"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import burgerLottie from '~/assets/json/lottie/burger-menu.json';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const section = usePageSection(props);
const eventTitle = inject('eventTitle');

// Refs
const isBurgerMenuOpen = ref(false);
const burgerLottieRef = ref(null);

// Watchers
watch(() => isBurgerMenuOpen.value, () => {
  if (!isBurgerMenuOpen.value) {
    document.body.classList.remove('lock-scroll');
    burgerLottieRef.value?.lottieRef?.playSegments?.([40, 80], true);
  } else {
    document.body.classList.add('lock-scroll');
    burgerLottieRef.value?.lottieRef?.playSegments?.([0, 40], true);
  }
});
</script>

<style>
.lottie-burger {
  svg {
    @apply !scale-[4.5];

    path {
      @apply stroke-(fg-subtle);
    }
  }
}
</style>
